import { Logo } from '../../ui-kit/assets';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ActionButton, Input } from '../../ui-kit/components';
import moment from 'moment'
import { Skeleton } from '@mui/material';

import styles from './WithdrawalFund.module.scss';
import { chooseFund, getBalanceInvestment, getBalanceWallet, getFundByCpu, getFundsUser, getTotalBalance } from '../../http/fundApi';
import { alertDanger, alertSuccess, DASHBOARD_ROUTER } from '../../utils/consts';
import { actionSetBalance, actionSetBalanceInvestment, actionSetBalanceWallet, actionSetFunds } from '../../store/actionCreators/fundActionCreator';
import { useDispatch, useSelector } from 'react-redux';

export const WithdrawalFundStep1 = () => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { cpu, id } = useParams();

  const [amount, setAmount] = useState();
  const [fund, setFund] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [is_fixed_fund, setIsFixedFund] = useState(null);
  const [time_for_finish, setTimeForFinish] = useState(null);

  const comission_summ_not_payment = useSelector((state) => state.fund.comission_summ_not_payment);
  const fund_user = useSelector((state) => state.fund.funds);
  
  const load_data = async () => {
    let fund_data = await getFundByCpu(cpu);
    setFund(fund_data);
    setIsFixedFund(fund_data.is_fixed_fund);
  }

  useEffect(() => {
    load_data();
  },[]);


  
  const formatTime = (seconds) => {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);

    let result = "";
    if (days > 0) result += `${days} дн. `;
    if (hours > 0) result += `${hours} ч. `;
    if (minutes > 0) result += `${minutes} мин.`;

    return result.trim();
  }
  
  let min = moment().format('m');
  let sec = moment().format('s');
  
  let min_will = 60 - Number(min) - 1;
  let sec_will = 60 - Number(sec);

  useEffect(() => {
    if (fund_user) {
      fund_user.map((f) => {
        if (f.investment_id == id) {
          const investment = f.investment;
          setTimeForFinish(f.time_for_finish);
          setIsButtonDisabled(false);
        }
      });
    }
  }, [fund_user, fund]);


  useEffect(() => {
    if (is_fixed_fund == 0) {
      setIsButtonDisabled(false);
    }
  },[is_fixed_fund]);

  return (
    <>
      <div className={styles.font}></div>
      <div className={styles.body}>
        <div className={styles['content']}>
          
          <div>Этап 1/2</div>
          <div style={{textAlign: 'center', marginBottom: 15}}>
            <img className={styles['logo-img']} src={Logo}/>
          </div>

          <h1 className={styles.title}>Вывести из фонда </h1>
          <h1 className={styles.title}>"{ fund?.name }" </h1>

          <h3 className={styles.under_title}>Ознакомьтесь с условиями управляющего</h3>
        
          
          { is_fixed_fund == 0 && <p className={styles.li}>При полном или частичном выводе средств осуществляется досрочный расчет вознаграждения управляющего. На последний расчетный час накопленная комиссия составляет { comission_summ_not_payment } USD, которая будет списана перед выводом.</p> }
          { is_fixed_fund == 1 && <p className={styles.li} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Выход из фонда сработает автоматически через &nbsp; { time_for_finish == null && <div style={{display: 'inline-block'}}><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={80} height={18} /></div> } { time_for_finish !== null && <span style={{color: 'white'}}>{formatTime(time_for_finish)}</span> }</p> }

          { is_fixed_fund == 1 && <div className={styles.attention}>Обратите внимание: при досрочном выводе средств вся ранее заработанная прибыль будет утрачена!</div> }

          <div className={styles.form_footer}>
            <ActionButton onClick={() => navigate('/investments')} className={styles.button_cancel}>Отменить</ActionButton>
            <ActionButton disabled={isButtonDisabled} onClick={() => navigate(`/withdrawal-fund-step-2/${cpu}/${id}`)} className={styles.button}>Далее</ActionButton>
          </div> 

        </div>
      </div>
    </> 
  );
};

import { Logo, UsdIcon } from '../../ui-kit/assets';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ActionButton, Input } from '../../ui-kit/components';

import styles from './WithdrawalFund.module.scss';
import { chooseFund, getBalanceInvestment, getBalanceWallet, getFundByCpu, getFundsUser, getTotalBalance, outFund } from '../../http/fundApi';
import { alertDanger, alertSuccess, DASHBOARD_ROUTER } from '../../utils/consts';
import { actionSetBalance, actionSetBalanceInvestment, actionSetBalanceWallet, actionSetFunds } from '../../store/actionCreators/fundActionCreator';
import { useDispatch, useSelector } from 'react-redux';

export const WithdrawalFundStep2 = () => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const balance_wallet = useSelector((state) => state.fund.balance_wallet);

  const { cpu, id } = useParams();

  const [amount, setAmount] = useState();
  const [fund, setFund] = useState({});
  const [max, setMax] = useState(0);
  const [is_fixed_fund, setIsFixedFund] = useState(null);

  const handlerMax = () => {
    setMax(1);
  }

  const clickBtnWithdrawalFund = async () => {
    try {
      let data = await outFund(amount, cpu, id, max);
      alertSuccess(dispatch,data.message);
      navigate(`/withdrawal-fund-step-3/${cpu}/${id}`);
    } catch (e) {
      alertDanger(dispatch,e.response.data.message);
    }
  }

  const load_data = async () => {
    let fund_data = await getFundByCpu(cpu);
    setFund(fund_data);
    setIsFixedFund(fund_data.is_fixed_fund);
    if (fund_data.is_fixed_fund == 1) {
      setAmount(0);
      setMax(1);
    }
  }


  useEffect(() => {
    load_data();
  },[])

  return (
    <>

      <div className={styles.font}></div>
      <div className={styles.body}>
        <div className={styles['content']}>
          
          <div>Этап 2/2</div>
          <div style={{textAlign: 'center', marginBottom: 15}}>
            <img className={styles['logo-img']} src={Logo}/>
          </div>

          <h1 className={styles.title}>Вывести из фонда </h1>
          <h1 className={styles.title}>"{ fund?.name }" </h1>

          { is_fixed_fund == 0 && <>

            <h3 className={styles.under_title}>Введите сумму</h3>

            { max == 0 && (
              <div className={styles.flex_amount}>
                <Input icon={UsdIcon} defaultValue={amount} setInputValue={setAmount} type="number" label="Введите сумму" inputClassName={styles.input_class_name} className={styles.input__wrapper_data} />
                <div style={{paddingLeft: 10}}><ActionButton onClick={() => handlerMax()} className={styles.button_max}>MAX</ActionButton></div>
              </div>            
            )}

            { max == 1 && (
              <div className={styles.flex_amount}>
                Выводим всю сумму из фонда, даже если она изменится в большую или меньшую сторону.
              </div>            
            )}


            <div className={styles.form_footer}>
              <ActionButton onClick={() => navigate(`/withdrawal-fund-step-1/${cpu}/${id}`)} className={styles.button_cancel}>Назад</ActionButton>
              <ActionButton onClick={() => clickBtnWithdrawalFund()} className={styles.button}>Подтвердить</ActionButton>
            </div>

          </> }





          { is_fixed_fund == 1 && <>

            <br/>

            { max == 1 && (
              <div className={styles.flex_amount}>
                Подтвердите выход из фонда с потерей всей накопленной прибыли
              </div>            
            )}


            <div className={styles.form_footer}>
              <ActionButton onClick={() => navigate(`/withdrawal-fund-step-1/${cpu}/${id}`)} className={styles.button_cancel}>Назад</ActionButton>
              <ActionButton onClick={() => clickBtnWithdrawalFund()} className={styles.button}>Подтвердить</ActionButton>
            </div>

          </> }

        </div>
      </div>

    </> 
  );
};

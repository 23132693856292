import { Logo, UsdIcon } from '../../ui-kit/assets';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ActionButton, Input } from '../../ui-kit/components';

import styles from './ChooseFund.module.scss';
import { chooseFund, getBalanceInvestment, getBalanceWallet, getFundByCpu, getFundsUser, getTotalBalance } from '../../http/fundApi';
import { alertDanger, alertSuccess, DASHBOARD_ROUTER } from '../../utils/consts';
import { actionSetBalance, actionSetBalanceInvestment, actionSetBalanceWallet, actionSetFunds } from '../../store/actionCreators/fundActionCreator';
import { useDispatch, useSelector } from 'react-redux';

export const ChooseFundStep2 = () => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const balance_wallet = useSelector((state) => state.fund.balance_wallet);
  const { cpu } = useParams();

  const [minimumAmount, setMinimumAmount] = useState();
  const [amount, setAmount] = useState();
  const [fund, setFund] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isButtonDisabledBack, setIsButtonDisabledCancel] = useState(false);

  useEffect(() => {
    setMinimumAmount(fund?.minimum_investment_amount_usd);
  }, [fund]);

  useEffect(() => {
    setIsButtonDisabled(true);

    if (amount && minimumAmount) {
      try {
        if (amount >= minimumAmount) {
          setIsButtonDisabled(false);
        } 
      } catch (e) {

      }
    } 
  }, [minimumAmount, amount]);

  const clickBtnChooseFund = async () => {
    setIsButtonDisabled(true);
    setIsButtonDisabledCancel(true);
    try {
      let data = await chooseFund(amount, cpu);

      let total_balance = await getTotalBalance();
      dispatch(actionSetBalance(total_balance.balance));

      let fund_user = await getFundsUser();
      dispatch(actionSetFunds(fund_user));
        
      let balance_wallet = await getBalanceWallet();
      dispatch(actionSetBalanceWallet(balance_wallet.amount));

      let balance_investment = await getBalanceInvestment();
      dispatch(actionSetBalanceInvestment(balance_investment.amount));

      alertSuccess(dispatch, data.message);
      navigate('/choose-fund-step-3/' + cpu);

    } catch (e) {
      alertDanger(dispatch, e.response.data.message);
    } finally {
      setIsButtonDisabled(false); // Разблокируем кнопку
      setIsButtonDisabledCancel(false); // Разблокируем кнопку
    }
  }

  const load_data = async () => {
    let fund_data = await getFundByCpu(cpu);
    setFund(fund_data);
  }

  const choose_max = () => {
    setAmount(balance_wallet);
  }

  useEffect(() => {
    load_data();
  }, [])

  return (
    <>

      <div className={styles.font}></div>
      <div className={styles.body}>
        <div className={styles['content']}>

          <div>Этап 2/2</div>
          <div style={{textAlign: 'center', marginBottom: 15}}>
            <img className={styles['logo-img']} src={Logo}/>
          </div>

          <h1 className={styles.title}>Инвестировать в фонд </h1>
          <h1 className={styles.title}>"{ fund?.name }" </h1>

          <h3 className={styles.under_title}>Введите сумму</h3>

          <div className={styles.flex_amount}>
            <Input icon={UsdIcon} defaultValue={amount} onBlur={setAmount} setInputValue={setAmount} type="number" label="Введите сумму" inputClassName={styles.input_class_name} className={styles.input__wrapper_data} />
            <div style={{paddingLeft: 10}}><ActionButton onClick={() => choose_max()} className={styles.button_max}>MAX</ActionButton></div>
          </div>

          { fund?.minimum_investment_amount_usd > 0 && <p className={styles.li}>Минимальная сумма входа в фонд &nbsp;<span style={{fontWeight: '600', color: 'white'}}>{ fund?.minimum_investment_amount_usd } USD</span>.</p> }

          <div className={styles.form_footer}>
            <ActionButton 
              onClick={() => navigate('/choose-fund-step-1/' + cpu)} 
              className={styles.button_cancel}
              disabled={isButtonDisabledBack}
            >
              Назад
            </ActionButton>
            <ActionButton 
              onClick={() => clickBtnChooseFund()} 
              className={styles.button} 
              disabled={isButtonDisabled}
            >
              Подтвердить
            </ActionButton>
          </div>

        </div>
      </div>

    </> 
  );
};


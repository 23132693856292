import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addBalanceChange, deleteBalanceChange, getAllBalances, getBalanceChange, getComission, getFundData, getSettings, getUsers, updateFund } from '../../../http/adminApi';
import { ActionButton, Input, Select } from '../../../ui-kit/components';
import { alertDanger, alertSuccess } from '../../../utils/consts';
import moment from 'moment';
import styles from './AdminSetting.module.scss';

export const AdminSetting = () => {

  let [comission, setComission] = useState(null)
  const [funds, setFunds] = useState([])
  const [users, setUsers] = useState([])
  const [ total_amount_comission, setTotalAmountComission ] = useState(0)
  const [balances, setBalances] = useState([]);

  const [fundInputs, setFundInputs] = useState({});

  const dispatch = useDispatch()
  
  const times = [
    { name: '00:00', value: 0 },
    { name: '01:00', value: 1 },
    { name: '02:00', value: 2 },
    { name: '03:00', value: 3 },
    { name: '04:00', value: 4 },
    { name: '05:00', value: 5 },
    { name: '06:00', value: 6 },
    { name: '07:00', value: 7 },
    { name: '08:00', value: 8 },
    { name: '09:00', value: 9 },
    { name: '10:00', value: 10 },
    { name: '11:00', value: 11 },
    { name: '12:00', value: 12 },
    { name: '13:00', value: 13 },
    { name: '14:00', value: 14 },
    { name: '15:00', value: 15 },
    { name: '16:00', value: 16 },
    { name: '17:00', value: 17 },
    { name: '18:00', value: 18 },
    { name: '19:00', value: 19 },
    { name: '20:00', value: 20 },
    { name: '21:00', value: 21 },
    { name: '22:00', value: 22 },
    { name: '23:00', value: 23 },
  ];
  const option_setting = [
    {
      name: 'Включен',
      value: 1,
    },
    {
      name: 'Выключен',
      value: 0,
    }
  ];
  const option_visible = [
    {
      name: 'Все',
      value: 'all',
    },
    {
      name: 'Только администраторы',
      value: 'admin',
    }
  ];

  useEffect(() => {
    const updatedInputs = funds.reduce((acc, item) => {
      acc[item.data._id] = { selectedDate: '', selectedTime: 0, selectedAmount: '' };
      return acc;
    }, {});
  
    setFundInputs((prev) => {
      const newState = { ...prev, ...updatedInputs };
      console.log('New Fund Inputs:', newState); // Проверка нового состояния
      return newState;
    });  
  }, [funds]);

  const _asyncLoad = async () => {
    let data;
    
    let temp_comission = await getComission()
    setComission(temp_comission)
    console.log(temp_comission)

    data = await getFundData()
    setFunds(data)
    
    await loadBalances();

    data = await getUsers()
    setUsers(data)

    if (data.length > 0) {
      let total_amount_comission_temp = 0
      data.map((item) => {
        total_amount_comission_temp += item.comission_to_pay
      })
      setTotalAmountComission(total_amount_comission_temp)
    }

    // data = await getAllBalances()
    // setBalances(data)
  }

  const updateSettingParserBalance = async (ob, fund_id) => {
    try {
      let data = await updateFund(fund_id, 'parser_balance', ob.value)
      alertSuccess(dispatch, data.message)
    } catch(e) {
      alertDanger(dispatch, e.response.data.message)
    }
  }

  const updateSettingVisible = async (ob, fund_id) => {
    try {
      let data = await updateFund(fund_id, 'visible', ob.value)
      alertSuccess(dispatch, data.message)
    } catch(e) {
      alertDanger(dispatch, e.response.data.message)
    }
  }

  const updateSettingCreditSumm = async (val, fund_id) => {
    if (val == "") val = 0
    try {
      let data = await updateFund(fund_id, 'credit_summ_fund', val)
      alertSuccess(dispatch, data.message)
    } catch(e) {
      alertDanger(dispatch, e.response.data.message)
    }
  }



  const updateFundInput = (id, field, value) => {
    setFundInputs((prev) => {
      const updated = {
        ...prev,
        [id]: {
          ...prev[id],
          [field]: value,
        },
      };
      console.log('Updated fundInput:', updated);
      return updated;
    });
  };

  const handleAddBalance = async (fund_id) => {
    const { selectedDate, selectedTime, selectedAmount } = fundInputs[fund_id] || {};

    if (!selectedDate || !selectedAmount) {
      alertDanger(dispatch, 'Все поля должны быть заполнены');
      return;
    }

    try {
      await addBalanceChange(fund_id, selectedAmount, selectedDate, selectedTime);
      await loadBalances();

      alertSuccess(dispatch, 'Баланс успешно добавлен');

      /*setFundInputs((prev) => {
        const updatedInputs = {
          ...prev,
          [fund_id]: { selectedDate: '', selectedTime: 0, selectedAmount: '' },
        };
        console.log('Updated Inputs:', updatedInputs); // Для отладки
        return updatedInputs;
      });  */

    } catch (error) {
      alertDanger(dispatch, error.response?.data?.message || 'Ошибка при добавлении баланса');
    }
  };



  // Функция для удаления баланса
  const handleDeleteBalance = async (id) => {
    try {
      await deleteBalanceChange(id);
      setBalances(balances.filter(balance => balance._id !== id)); // Удаляем баланс из списка
      alertSuccess(dispatch, 'Баланс успешно удалён');
    } catch (error) {
      alertDanger(dispatch, error.response.data.message);
    }
  };

  // Загрузка данных для не сработанных балансов
  const loadBalances = async () => {
    try {
      const data = await getBalanceChange();
      setBalances(data);
    } catch (error) {
      console.error('Ошибка загрузки балансов:', error);
    }
  };

  useEffect(() => {
    _asyncLoad()
  }, [])
  
  return (
    <div className={styles.body}>

      <div>
        { funds.length === 0 && <div>Идет загрузка данных ...</div>}
        { funds.map((item) => {


          const fundInput = fundInputs[item.data._id] || {
            selectedDate: '',
            selectedTime: 0,
            selectedAmount: '',
          };

          const setting_balance = {
            name: item.data.parser_balance == "1" ? 'Включен' : 'Выключен',
            value: parseFloat(item.data.parser_balance),
          };

          const setting_visible = {
            name: item.data.visible == "all" ? 'Все' : 'Только администраторы',
            value: item.data.parser_balance,
          }
          
          const credit_summ = item.data.credit_summ_fund

          return (
            <div key={`id_${item.data._id}`}>
              <h1 className={styles.title}>{ item.data.name }</h1>

                      
              <table className={styles.table_setting}>

                <tr>
                  <td>Парсер баланса</td>
                  <td><Select onChange={(v) => updateSettingParserBalance(v, item.data._id)} selectedOption={setting_balance} options={option_setting} placeholder='Парсер баланса' /></td>
                </tr>

                { credit_summ !== "" && (
                  <tr>
                    <td>Временно вывели из фонда, $</td>
                    <td><Input type="number" onBlur={(v) => updateSettingCreditSumm(v, item.data._id)} defaultValue={credit_summ}/></td>
                  </tr>
                )}

                <tr>
                  <td>Видимость фонда</td>
                  <td><Select onChange={(v) => updateSettingVisible(v, item.data._id)} selectedOption={setting_visible} options={option_visible} placeholder='Видимость фонда' /></td>
                </tr>

              </table>

              <br/>
















              <h1>Расставить заранее баланс по фонду "{item.data.name}"</h1>

              <br />

              <div className={styles.add_balance_change}>
                <div className={styles.titleTable}>Дата</div>
                <div className={styles.titleTable}>Время</div>
                <div className={styles.titleTable}>Временно выведенная сумма</div>
                <div className={styles.titleTable}></div>
              </div>

              <div className={styles.add_balance_change}>
                <div>
                  <Input
                    type="date"
                    placeholder="Дата"
                    defaultValue={fundInputs[item.data._id]?.selectedDate || ''}
                    setInputValue={(val) => updateFundInput(item.data._id, 'selectedDate', val)}
                    onBlur={(val) => updateFundInput(item.data._id, 'selectedDate', val)}
                  />
                </div>
                <div>
                  <Select
                    options={times}
                    placeholder="Время"
                    onChange={(ob) => updateFundInput(item.data._id, 'selectedTime', ob.value)}
                    selectedOption={times.find((t) => t.value === (fundInputs[item.data._id]?.selectedTime ?? null))}
                  />
                </div>
                <div>
                  <Input
                    type="number"
                    defaultValue={fundInputs[item.data._id]?.selectedAmount || ''}
                    placeholder="Временно выведенная сумма"
                    setInputValue={(val) => updateFundInput(item.data._id, 'selectedAmount', val)}
                    onBlur={(val) => updateFundInput(item.data._id, 'selectedAmount', val)}
                  />
                </div>
                <div>
                  <ActionButton onClick={() => handleAddBalance(item.data._id)}>
                    Добавить баланс
                  </ActionButton>
                </div>
              </div>

              <br/>

              <div>
                <div>Еще не сработанные балансы</div>
                <br/>
                <table className={styles.table_stat_fund}>
                  <tr>
                    <td style={{width: 220}}>Дата</td>
                    <td style={{width: 220, textAlign: 'center'}}>Сумма</td>
                    <td style={{width: 220, textAlign: 'center'}}>Дельта<br/>между текущим</td>
                    <td style={{width: 220, textAlign: 'center'}}>Дельта<br/>между предыдущим</td>
                    <td></td>
                    <td style={{width: 130}}>Действие</td>
                  </tr>
                  {balances
                    .filter((balance) => balance.fund_id === item.data._id)
                    .map((balance) => ( 
                      <tr key={balance._id}>
                        <td>{moment(new Date(balance.date)).format('DD/MM/YYYY')} {balance.time}:00 МСК</td>
                        <td style={{textAlign: 'center'}}>{balance.amount} $</td>
                        <td style={{textAlign: 'center'}}>{balance.delta_from_start} $</td>
                        <td style={{textAlign: 'center'}}>{balance.delta_from_prev} $</td>
                        <td></td>
                        <td>
                          <span
                            className={styles.delete_button}
                            onClick={() => handleDeleteBalance(balance._id)}
                          >удалить</span>
                        </td>
                      </tr>
                    ))}
                </table>
              </div>
                 
              <br/><br/><br/>

              <h1>Текущие финансовые показатели по фонду "{ item.data.name }"</h1>

              <br/>

              <table className={styles.table_stat_fund}>
                <tr>
                  <td>Баланс пользователей в фонде</td>
                  <td>Баланс фонда на кошельке</td>
                  <td>Баланс фонда по открытым позициям</td>
                  <td>Временно вывели из фонда *</td>
                  <td>Дельта по фонду</td>
                  <td style={{backgroundColor: "rgb(240,240,240)"}}></td>
                  <td>Баланс пользователей на кошельке</td>
                  <td>Реальный баланс на кошельке</td>
                  <td>Дельта по кошельку</td>
                </tr>

                <tr>
                  <td>{ item.user_amount_fund }$</td>
                  <td>{ item.balance_fund_wallet }$</td>
                  <td>{ item.balance_fund_active }$</td>
                  <td>{ item.credit_summ_fund }$</td>
                  <td>{ item.delta_fund }$</td>
                  <td style={{backgroundColor: "rgb(240,240,240)"}}></td>
                  <td>{ item.user_amount_wallet }$</td>
                  <td>{ item.balance_wallet }$</td>
                  <td>{ item.delta_wallet }$</td>
                </tr>

              </table>

              <div style={{marginTop: 30, marginBottom: 100}}> 
                <div>* -Временно вывели из фонда - эта сумма прибавляется к реальному балансу фонда.</div>
              </div>

            </div>
          )
        })}
      </div>




      <h1 className={styles.title} style={{marginTop: 40}}>Текущие пользователи</h1>

      <div>

        { users.length === 0 && <div>Идет загрузка данных ...</div>}

        { users.length > 0 && (

          <table className={styles.table_stat_fund}>

            <tr>
              <td>Email</td>
              <td>Баланс на кошельке</td>
              <td>Инвестированная сумма</td>
              <td>Сумма сейчас</td>
              <td>Сумма к погашению</td>
              <td>Комисиия сейчас</td>
            </tr>
          
            { users.map((item, i) => {

              return (
                <tr key={"row_" + i}>
                  <td>{ item.email }</td>
                  <td>{ item.amount_usd }$</td>
                  <td>{ item.amount_investment }$</td>
                  <td>{ item.amount_investment_now }$</td>
                  <td>{ item.comission_to_pay }$</td>
                  <td>{ item.comission }%</td>
                </tr>
              )
            })}

          </table>

        )}
      
      </div>


      { total_amount_comission > 0 && <div style={{marginTop: 15}}>Суммарная комиссия к погашению: { total_amount_comission } $</div> }



      { false && <>
        
        <h1 className={styles.title} style={{marginTop: 40}}>Все балансы</h1>

        <div>

          { balances.length === 0 && <div>Идет загрузка данных ...</div>}

          { balances.length > 0 && (

            <table className={styles.table_stat_fund}>

              <tr>
                <td>balance_id</td>
                <td>Дата</td>
                <td>Баланс в balance</td>
                <td>Баланс в user balance</td>
                <td></td>
              </tr>
            
              { balances.map((item) => {
                return (
                  <tr>
                    <td>{ item.balance_id }</td>
                    <td>{ item.created_on }</td>
                    <td>{ item.balance_amount }$</td>
                    <td>{ item.user_balance_amount }$</td>
                    <td><span className='fa fa-times'></span></td>
                  </tr>
                )
              })}

            </table>

          )}
        
        </div>

        
      </>}
      


    </div>
  );
};

import styles from './Withdrawal.module.scss';
import { BTC, Logo, UsdIcon } from '../../ui-kit/assets';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { calculateComissionWithdrawal, getCourse, withdrawalStep1 } from '../../http/paymentApi';
import { ActionButton, Checkbox, Input, Select } from '../../ui-kit/components';
import { alertDanger, GOOGLE_AUTH_INFO_ROUTER, GOOGLE_AUTH_ROUTER, options_currency } from '../../utils/consts';
import { useDispatch, useSelector } from 'react-redux';

export const WithdrawalFirst = () => {
  
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user = useSelector((state) => state.user.user)
  if (user.google_auth == 0) navigate(GOOGLE_AUTH_INFO_ROUTER)

  const [ symbol_ob, setSymbolOb ] = useState(options_currency[0])
  const [ symbol, setSymbol ] = useState('BTC')
  const [ crypto_wallet, setCryptoWallet ] = useState('')
  const [ amount_crypto, setAmountCrypto ] = useState('')
  const [ amount_usd, setAmountUsd ] = useState('')
  const [ course, setCourse ] = useState('')
  const [ courses, setCourses ] = useState({})
  const [ comission, setComission ] = useState(options_currency[0].comission)

  const load_data = async () => {
    
    let data = await getCourse()
    setCourses(data)
    setCourse(data.BTC)

  }

  
  const changeCurrency = (o) => {
    setSymbolOb(o)
    setSymbol(o.value)
    setComission(o.comission)
    setCourse(courses[o.value.replace(' ','_')])
  }

  const setAmountInputBtc = (val) => {
    val = parseFloat(val)
    setAmountCrypto(val)
    if (!isNaN(val) && !isNaN(course)) {
      setAmountUsd(Math.floor(val * course * 10) / 10)
    } else {
      setAmountUsd('')
    }
  }

  const setAmountInputUsd = (val) => {
    val = parseFloat(val)
    setAmountUsd(val)
    if (!isNaN(val) && !isNaN(course)) {
      setAmountCrypto(Math.floor(val / course * 1000000) / 1000000)
    } else {
      setAmountCrypto('')
    }
  }

  /*const calculateion = async () => {
    if (crypto_wallet != '' && amount_crypto !== '') {
      let comission = await calculateComissionWithdrawal(crypto_wallet, amount_crypto);
    }
  }*/



  useEffect(() => {
    load_data()
  },[])

  /*useEffect(() => {
    calculateion();
  },[crypto_wallet, amount_crypto]);*/

  const clickBtnWithdrawal = async () => {

      if (amount_crypto === '') {
          alertDanger(dispatch, "Введите сумму для списания")
          return
      }

      if (crypto_wallet === '') {
          alertDanger(dispatch, "Введите адрес Bitcoin кошелька")
          return
      }

      try {

        let data = await withdrawalStep1(amount_usd, crypto_wallet, symbol)
        console.log('/withdrawal-step-2/' + data._id) 

        navigate('/withdrawal-step-2/' + data._id)
    
      } catch(e) {
        
        alertDanger(dispatch, e.response.data.message)

      }

  }



  return (
    <>
      <div className={styles.font}></div>
      <div className={styles.body}>
        <div className={styles['content']}>
          
          <div>Этап 1/3</div>
          <div style={{textAlign: 'center', marginBottom: 15}}>
            <img className={styles['logo-img']} src={Logo}/>
          </div>

          <h1 className={styles.title}>Вывод средств</h1>

          <div className={styles.form}>

            
            <div className={styles.input} style={{marginBottom: 30}}>
                <Select options={options_currency} onChange={changeCurrency} className={styles.input__wrapper__center} placeholder={"Выберите валюту вывода"} selectedOption={symbol_ob}></Select>
            </div>
            
            
            <div className={styles['flex-input']}>

              <div className={styles.input}>
                  <Input setInputValue={setAmountInputBtc} type="number" label={`Сумма в ${symbol}`} inputClassName={styles['input-left']} icon={symbol_ob.image} defaultValue={amount_crypto} className={styles.input__wrapper} />
              </div>

              <div style={{marginTop: '-30px', textAlign: 'center'}}> = </div>

              <div className={styles.input}>
                  <Input setInputValue={setAmountInputUsd} type="number" label="Сумма в USD" inputClassName={styles['input-right']} icon={UsdIcon} defaultValue={amount_usd}  className={styles.input__wrapper} />
              </div>

            </div>
            
            
            <div className={styles.input}>
                <Input setInputValue={setCryptoWallet} label={`Введите ${symbol} адрес`} className={styles.input__wrapper__center} />
            </div>

            <div className={styles.notification}>Комиссия за транзакцию составит ≈ {comission} {symbol}</div>
            <ActionButton onClick={() => clickBtnWithdrawal()} className={styles.button}>Далее</ActionButton>
          </div>

          
          <div className={styles.text}>Вывод осуществляется только в BTC, ETH, USDt TRC20. Min сумма вывода 10USD</div>

        </div>
      </div>
    </> 
  );
};

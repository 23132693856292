import { useWindowSize } from '../../hooks';

import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { getHistory, getHistoryFund } from '../../http/paymentApi';
import { Skeleton } from '@mui/material';

import styles from './Transactions.module.scss';
import { prettyUsd, prettyUsdWithSign, TRANSACTIONS_ROUTER } from '../../utils/consts';

export const TransactionsFund = () => {

  const { isMobile } = useWindowSize();
  const dispatch = useDispatch()

  const [ history, setHistory ] = useState([])
  const [loading, setLoading] = useState(true);

  const load_data = async () => {
    let data = await getHistoryFund()
    setHistory(data)
    setLoading(false);
  }

  useEffect(() => {
    load_data()
  },[])

  return (
    <>
      <div className={styles.body}>
      <div className={styles.title}><Link to={TRANSACTIONS_ROUTER} style={{color: 'rgb(111,111,111)'}}>Транзакции по кошельку</Link> &nbsp; &nbsp; Транзакции по фондам </div>
        <div style={{overflow:'auto', paddingBottom: 20}}>
          <table className={styles.table}>
            <thead>
              <tr>
                <td>Дата</td>
                <td>Описание</td>
                <td>Сумма USD</td>
                <td>Статус</td>
              </tr>
            </thead>
            <tbody>

              { loading && (
                <>
                  <tr>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                  </tr>
                  <tr>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                  </tr>
                  <tr>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                  </tr>
                </>
              )}

              { loading == false && history.length == 0 && (
                <tr>
                  <td colSpan={4}>
                    Транзакций по фонду ещё не было.
                  </td>
                </tr>
              )}

              { history.length > 0 && (
                history.map((item) => {
                  return (
                    <tr>
                      <td>{ item.date }</td>
                      <td>
                        <div>{ item.status_text }</div>
                        <div style={{marginTop: 5, color: 'rgb(111,111,111)'}}>Фонд "{ item.fund_name }"</div>
                      </td>
                      <td>
                        <div>{ item.real_usd > 0 ? prettyUsdWithSign(item.value_usd) : '' }</div>
                          { item.text_comission !== '' && (
                            <div style={{fontSize: 12, color: "rgb(150,150,150)", marginTop: 10}}>{ item.text_comission }</div>
                          )}
                      </td>
                      <td>{ item.status == 0 ? 'Не подтвержден' : (item.status == 1 ? 'Подтвержден' : 'В процессе') }</td>
                    </tr>
                  )
                })
              )}
            </tbody>
          </table>
        </div>
        
        <div className={styles.warning}>Проблема с транзакцией? Свяжитесь с нами support@x3crypto.fund</div>

      </div>
    </>
  );
};

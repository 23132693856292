import styles from './FundProperty.module.scss';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { getStatFundDistribution } from '../../../http/fundApi';
import { useEffect, useState } from 'react';
import { FundPropCalendarIcon, FundPropCapitalIcon, FundPropInvestingIcon, FundPropPersonIcon, FundPropPersonsIcon, FundPropProfitIcon } from '../../../ui-kit/assets';
import moment from 'moment'
import { prettyUsd } from '../../../utils/consts';

export const FundProperty = (params) => {

  const [name, setName] = useState("")
  const [capital_myself, setCapitalMyself] = useState("")
  const [amount_investor, setAmountInvestor] = useState("")
  const [date, setDate] = useState("")
  const [capital, setCapital] = useState("")
  const [profit, setProfit] = useState("")


  useEffect(() => {

    if (params.fund) {
        
      setName(params.fund.name)
      setCapitalMyself(params.fund.capital_myself)
      setAmountInvestor(params.fund.amount_investor)
      setCapital(params.fund.capital_total)
      setProfit(params.fund.profit_amount)
      setDate(moment(params.fund.date_created).format("MM.DD.YYYY"))

    }

  },[params])

  return (
    <div className={styles.body}>
      <div className={styles.title}>Свойства фонда</div>
      <div className={styles['chart-block']}>
        
        <div className={styles['fund-prop-item-container']}>

          <div className={styles['fund-prop-item']}>
            <div className={styles['fund-prop-item-left']}><img src={FundPropPersonIcon} className={styles['fund-prop-icon']}/></div>
            <div className={styles['fund-prop-item-right']}>
              <div className={styles['fund-prop-item-title']}>Название</div>
              <div className={styles['fund-prop-item-name']}>{ name }</div>
            </div>
          </div>

          <div className={styles['fund-prop-item']}>
            <div className={styles['fund-prop-item-left']}><img src={FundPropCalendarIcon} className={styles['fund-prop-icon']}/></div>
            <div className={styles['fund-prop-item-right']}>
              <div className={styles['fund-prop-item-title']}>Дата создания</div>
              <div className={styles['fund-prop-item-name']}>{ date }</div>
            </div>
          </div>

          <div className={styles['fund-prop-item']}>
            <div className={styles['fund-prop-item-left']}><img src={FundPropProfitIcon} className={styles['fund-prop-icon']}/></div>
            <div className={styles['fund-prop-item-right']}>
              <div className={styles['fund-prop-item-title']}>Доходность за все время</div>
              <div className={styles['fund-prop-item-name']}>{ prettyUsd(profit) } USD</div>
            </div>
          </div>

        </div>

        <div style={{clear: "both"}}></div>

        <div className={styles['fund-prop-item-container']}>
          
          <div className={styles['fund-prop-item']}>
            <div className={styles['fund-prop-item-left']}><img src={FundPropCapitalIcon} className={styles['fund-prop-icon']}/></div>
            <div className={styles['fund-prop-item-right']}>
              <div className={styles['fund-prop-item-title']}>Средства в управлении</div>
              <div className={styles['fund-prop-item-name']}>{ prettyUsd(capital) } USD</div>
            </div>
          </div>

          <div className={styles['fund-prop-item']}>
            <div className={styles['fund-prop-item-left']}><img src={FundPropPersonsIcon} className={styles['fund-prop-icon']}/></div>
            <div className={styles['fund-prop-item-right']}>
              <div className={styles['fund-prop-item-title']}>Количество инвесторов</div>
              <div className={styles['fund-prop-item-name']}>{ amount_investor }</div>
            </div>
          </div>

          <div className={styles['fund-prop-item']}>
            <div className={styles['fund-prop-item-left']}><img src={FundPropInvestingIcon} className={styles['fund-prop-icon']}/></div>
            <div className={styles['fund-prop-item-right']}>
              <div className={styles['fund-prop-item-title']}>Капитал управляющего</div>
              <div className={styles['fund-prop-item-name']}>{ prettyUsd(capital_myself) } USD</div>
            </div>
          </div>
          
        </div>

      </div>
    </div>
  );
};

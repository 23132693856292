import { Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ActionButton } from '../../../ui-kit/components';
import { FUNDS_ROUTER, prettyUsd } from '../../../utils/consts';
import styles from './ActiveFund.module.scss';

export const ActiveFund = () => {

  const funds = useSelector((state) => state.fund.funds)

  return (
    <div className={styles.body}>
      <div className={styles.title}>Фонды</div>
      <div style={{overflow:'auto', paddingBottom: 20}}>
        <table className={styles.table}>
          <thead>
            <tr>
              <td></td>
              <td>Наименование</td>
              <td>Динамика 24ч</td>
              <td>Инвестиции</td>
              <td>Сумма</td>
              <td>Прибыль</td>
              <td>Комиссия к погашению</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            { funds == null && (

              <tr>
                <td style={{paddingRight: 5}}><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={20} /></td>
                <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={20} /></td>
                <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={20} /></td>
                <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={20} /></td>
                <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={20} /></td>
                <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={20} /></td>
                <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={20} /></td>
                <td style={{width: 120}}>
                  <Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={20} />
                </td>
              </tr>

            )}

            { funds !== null && (

              <>

                { funds.map((item) => {
                  return (
                    <tr key={item.investment_id}>
                      <td style={{paddingRight: 5}}><div title={item.process_status === 'new' ? 'В процессе вступления' : 'В процессе'} className={styles[item.process_status === 'new' ? 'new' : 'process']}></div></td>
                      <td>{ item.fund.name }</td>
                      <td>{ item.change_24 > 0 ? '+' : '' }{ prettyUsd(item.change_24) } USD</td>
                      <td>{ prettyUsd(item.investment.amount) } USD </td>
                      <td>{ prettyUsd(item.balance) } USD</td>
                      <td>{ prettyUsd(item.profit_now) } USD</td>
                      <td>{item.fund.is_fixed_fund === 1 ? '-' : `${prettyUsd(item.profit_not_paid)} USD`}</td>
                      <td style={{width: 120}}>
                        { item.process_status != 'new' && 
                        <Link to={`/withdrawal-fund-step-1/${item.fund.cpu}/${item.investment_id}`}>
                          <div className={styles['button-more']}>Вывести</div>
                        </Link>
                        }
                      </td>
                    </tr>
                  )
                })}

              </>

            )}

          </tbody>
        </table>

        <br/>

        <div className={styles['flex-comment']}>
          <div className={styles['new']}></div>
          <div> &nbsp;  &nbsp; Ролловер в процессе</div>
        </div>

        <div className={styles['flex-comment']}>
          <div className={styles['process']}></div>
          <div> &nbsp;  &nbsp; Активные инвестиции</div>
        </div>

      </div>

      { funds !== null && funds.length == 0 && <div className={styles.warning}>В настоящий момент у вас нет активных фондов</div> }

      <div className={styles['button-container']}>
        <Link to={FUNDS_ROUTER}>
          <div className={styles.button}>Найти фонд</div>
        </Link>
      </div>
      
    </div>
  );
};

import { deleteLink, getLinks, addLink } from '../../../http/adminApi';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';

import styles from './AdminLink.module.scss';
import { useDispatch } from 'react-redux';
import { alertDanger, alertSuccess } from '../../../utils/consts';

export const AdminLink = () => {

  const [links, setLinks] = useState([]);
  const [email, setEmail ] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createLink = async () => {
    if (email != '') {
      try {
        await addLink(email)
        alertSuccess(dispatch, "Ссылка успешно добавлена");
        await load_data();
        setEmail('');
      } catch(e) {
        alertDanger(dispatch, e.response.data.message);
      }
    } else {
      alertDanger(dispatch, "Заполните Email");
    }
  }

  const load_data = async() => {
    let data = await getLinks();
    setLinks(data);
  }

  const deleteLinks = async(link_id) => {
    await deleteLink(link_id);
    await load_data();
  }

  useEffect(() => {
    load_data();
  }, []);

  return (
    <div className={styles.body}>
      <div className={styles.flex}>
        <div><h1 style={{fontSize: 20}}>Ссылки регистрации</h1></div>
      </div>
      <table className={styles.table}>

        <tr key="reg">
          <td colSpan={2}>
            <input className={styles.input} type="text" value={email} onChange={(e) => setEmail(e.target.value)}/>
          </td>
          <td>
            <input type="button" onClick={() => createLink()} value="Добавить" className={styles.button}/>
          </td>
          <td style={{width: 100, textAlign: 'right'}}> </td>
        </tr>
        
        { links.map((item) => {
          return (
            <tr key={item._id}>
              <td>{ moment(item.created_on).format("YYYY/MM/DD HH:mm") }</td>
              <td>{ item.email }</td>
              <td><a href={`https://x3crypto.fund/sign-up/${item._id}`} target="_blank">{`https://x3crypto.fund/sign-up/${item._id}`}</a></td>
              <td style={{width: 100, textAlign: 'right'}}>
                <span style={{cursor: "pointer"}} onClick={() => deleteLinks(item._id)}>[ удалить ]</span>
              </td>
            </tr>
          )
        })}
      </table>
    </div>
  );
};

import { $authHost, $host } from './index.js'


export const getWithdrawals = async () => {
  const {data} = await $authHost.get('api/admin/get_withdrawals')
  return data
}

export const getFundData = async () => {
  const {data} = await $authHost.get('api/admin/get_fund_data')
  return data
}

export const getComission = async () => {
    const {data} = await $authHost.get('api/admin/get_comission')
    return data
}
export const getSettings = async () => {
    const {data} = await $authHost.get('api/admin/get_settings')
    return data
}

export const getUsers = async () => {
  const {data} = await $authHost.get('api/admin/get-users')
  return data
}

export const getAllBalances = async () => {
  const {data} = await $authHost.get('api/admin/get-all-balances')
  return data
}

export const updateSetting = async (key, value) => {
  const {data} = await $authHost.post('api/admin/update_setting', {
    key, value
  })
  return data
}

export const deleteBalanceChange = async (id) => {
  const {data} = await $authHost.post('api/admin/delete-balance-change', {
    id
  })
  return data
}

export const getBalanceChange = async (fund_id) => {
  const {data} = await $authHost.get('api/admin/get-balance-change');
  return data
}

export const addBalanceChange = async (fund_id, amount, date, time) => {
  const {data} = await $authHost.post('api/admin/add-balance-change', {
    fund_id, amount, date, time
  })
  return data
}

export const updateFund = async (fund_id ,key, value) => {
  const {data} = await $authHost.post('api/admin/update_fund', {
    fund_id, key, value
  })
  return data
}


// NEWS

export const getNewsListAdmin = async () => {
  const {data} = await $authHost.get('api/admin/get_news_list')
  return data
}

export const getNewsAdmin = async (news_id) => {
  const {data} = await $authHost.get('api/admin/get_news?news_id=' + news_id)
  return data
}

export const deleteNewsAdmin = async (news_id) => {
  const {data} = await $authHost.get('api/admin/delete_news?news_id=' + news_id)
  return data
}

export const getLinks = async () => {
  const {data} = await $authHost.get('api/admin/get_links')
  return data
}

export const deleteLink = async (link_id) => {
  const {data} = await $authHost.get('api/admin/delete_link?link_id=' + link_id)
  return data
}


export const addNewsAdmin = async (title, description) => {
  const {data} = await $authHost.post('api/admin/add_news', {
    title, description
  })
  return data
}

export const addLink = async (email) => {
  const {data} = await $authHost.post('api/admin/add_link', {
    email
  });
  return data;
}

export const updateNewsAdmin = async (news_id, title, description) => {
  const {data} = await $authHost.post('api/admin/update_news', {
    news_id, title, description
  })
  return data
}
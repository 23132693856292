import { useWindowSize } from '../../hooks';

import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { getHistory } from '../../http/paymentApi';
import Parser from 'html-react-parser';
import { Skeleton } from '@mui/material';

import styles from './Transactions.module.scss';
import { prettyUsd, prettyUsdWithSign, TRANSACTIONS_FUND_ROUTER } from '../../utils/consts';

export const Transactions = () => {

  const { isMobile } = useWindowSize();
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true);
  const [ history, setHistory ] = useState([])

  const load_data = async () => {
    let data = await getHistory()
    setHistory(data)
    setLoading(false);
  }

  useEffect(() => {
    load_data()
  },[])

  return (
    <>
      <div className={styles.body}>
        <div className={styles.title}>Транзакции по кошельку &nbsp; &nbsp; <Link to={TRANSACTIONS_FUND_ROUTER} style={{color: 'rgb(111,111,111)'}}>Транзакции по фондам</Link> </div>
        <div style={{overflow:'auto', paddingBottom: 20}}>
          <table className={styles.table}>
            <thead>
              <tr>
                <td>Дата</td>
                <td>Описание</td>
                <td>Сумма в криптовалюте</td>
                <td>Сумма USD</td>
                <td>Статус</td>
              </tr>
            </thead>
            <tbody>

              { loading && (
                <>
                  <tr>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                  </tr>
                  <tr>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                  </tr>
                  <tr>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                    <td><Skeleton sx={{ bgcolor: '#33384F', borderRadius: 1 }} variant="rectangular" width={"70%"} height={18} /></td>
                  </tr>
                </>
              )}

              { loading == false && history.length == 0 && (
                <tr>
                  <td colSpan={5}>
                    Транзакций по кошельку ещё не было.
                  </td>
                </tr>
              )}

              { history.length > 0 && (
                history.map((item) => {
                  return (
                    <tr>
                      <td>{ item.date }</td>
                      <td>{ item.status_text }</td>
                      <td>{ item.real_btc != 0 ? item.value_btc : '' } <span style={{paddingLeft: 5, fontSize: 14,color: "rgb(180,180,180)"}}>{ item.symbol }</span></td>
                      <td>{ item.real_usd > 0 ? prettyUsdWithSign(item.value_usd) : '' }</td>
                      <td>
                        { item.action_text === '' ? (
                          <>
                            { Parser(item.final_status) }
                          </>
                        ) : (
                          <>
                            <Link to={ item.action_link }><div className={styles.action}>{ item.action_text }</div></Link>
                          </>
                        ) }
                      </td>
                    </tr>
                  )
                })
              )}
            </tbody>
          </table>
        </div>

        <div className={styles.warning}>Проблема с транзакцией? Свяжитесь с нами support@x3crypto.fund</div>

      </div>
    </>
  );
};
